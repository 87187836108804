import Header from "../Header"
import Can from "components/Can"
import Filters from "../Filters"
import useWallets from "../../hooks"
import WalletListTable from "../Table"
import SidebarFilters from "../SidebarFilters"
import WalletListTableScroll from "../TableScroll"
import { Desktop, Mobile } from "components/Devices"
import { DEFAULT_VALUES, OPTIONS } from "./constants"

import "../../WalletList.styl"

const History = ({ pageContext: { business } }) => {
  const { tableInfo, filters, mobile, sidebarFilter } = useWallets({
    DEFAULT_VALUES,
  })

  return (
    <Can
      business={business.business_permission}
      do="wallet-management"
      otherwise={{ goTo: ROUTES.home.path }}
    >
      <section className="wallet-list">
        <Header>
          <Filters
            options={OPTIONS}
            filters={filters.all}
            values={DEFAULT_VALUES}
            sidebarFilter={sidebarFilter}
            updateFilters={filters.updateFilters}
          />
        </Header>
        <main className="wallet-list__content">
          <Desktop>
            <WalletListTableScroll
              walletList={tableInfo.walletList}
              pagination={tableInfo.pagination}
              onPagination={tableInfo.refresh}
            />
          </Desktop>
          <Mobile>
            <WalletListTable
              walletList={tableInfo.walletList}
              mobile={mobile}
            />
          </Mobile>
        </main>
      </section>

      <SidebarFilters
        options={OPTIONS}
        filters={filters.all}
        resetValues={DEFAULT_VALUES}
        visibility={sidebarFilter.isOpen}
        closeSidebar={sidebarFilter.close}
        submitFilters={filters.submitFilters}
        hasToReset={sidebarFilter.hasToReset}
        clearReset={sidebarFilter.clearReset}
      />
    </Can>
  )
}

export default History
