export const DEFAULT_VALUES = {
  product_name: undefined,
  name_type_contract: undefined,
  delay_track_rating: undefined,
  name_number_operation_credit: "",
  due_date_amortization_installment: undefined,
  situation_operation_financing: "liquidada, cancelada",
  last_installment_situation: undefined,
}

export const OPTIONS = {
  SITUATIONS: [
    { value: "liquidada", text: "Liquidada" },
    { value: "cancelada", text: "Cancelada" },
  ],
  TYPES: [
    { value: "individual", text: "Individual" },
    { value: "Com avalista", text: "Com avalista" },
  ],
  DELAY: [
    { value: "Atraso Curtíssimo", text: "Atraso Curtíssimo" },
    { value: "Atraso Curto", text: "Atraso Curto" },
    { value: "Atraso Longo", text: "Atraso Longo" },
  ],
  PARCEL: [{ value: "Em Aberto", text: "Última Parcela" }],
  PRODUCT: [
    { value: "Microcrédito", text: "Microcrédito" },
    { value: "Renegociação", text: "Renegociação" },
  ],
}
